require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks';
import axios from 'axios';
import moment from 'moment';
import VueThailandAddress from 'vue-thailand-address';
import 'vue-thailand-address/dist/vue-thailand-address.css';

Vue.use(TurbolinksAdapter)
Vue.use(VueThailandAddress);

// var app
import App from 'packs/components/app.vue'
// if (window.location.hostname == "www.tunatag.com") {
    // app = require('packs/components/deesayApp.vue')
// } else {
    // app = require('packs/components/app.vue')
// }
Vue.component('app', App)
Vue.component('member', () => import('../packs/components/members/memberTable.vue'))
Vue.component('members', () => import('../packs/components/members/members.vue'))
Vue.component('login', () => import('packs/components/login.vue'))
Vue.component('orgChart', () => import('packs/components/members/orgChart.vue'))
Vue.component('memberform', () => import('packs/components/members/memberForm.vue'))
Vue.component('companyProductTable', () => import('packs/components/companyProductTable.vue'))
Vue.component('memberOrderTable', () => import('packs/components/members/memberOrderTable.vue'))
Vue.component('genericForm', () => import('packs/components/genericForm.vue'))
Vue.component('orderForm', () => import('packs/components/orders/orderForm.vue'))
Vue.component('orderIndex', () => import('packs/components/orders/orderIndex.vue'))
Vue.component('orderHistories', () => import('packs/components/orders/histories.vue'))
Vue.component('customerTable', () => import('packs/components/customers/customerTable.vue'))
Vue.component('customerOrderTable', () => import('packs/components/customers/customerOrderTable.vue'))
Vue.component('customerForm', () => import('packs/components/customers/customerForm.vue'))
Vue.component('newMemberForm', () => import('packs/components/members/newMemberForm.vue'))
Vue.component('stickerTable', () => import('packs/components/stickers/stickerIndex.vue'))
Vue.component('editPoint', () => import('packs/components/members/editPoint.vue'))
Vue.component('walletHistories', () => import('packs/components/wallets/histories.vue'))
Vue.component('stickerScanned', () => import('packs/components/stickers/scanned.vue'))
Vue.component('QrScanner', () => import('packs/components/stickers/scanner.vue'))
Vue.component('redeemedList', () => import('packs/components/rewards/redeemedList.vue'))

import Buefy from 'buefy';
Vue.use(Buefy, { defaultIconPack: 'mdi', materialDesignIcons: true, defaultDateFormatter: (date) => moment(date).format("DD/MM/YYYY") });

document.addEventListener('turbolinks:load', () => {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['Accept'] = 'application/json'
    const app = new Vue({
        data: {
            name: 'App'
        },
        // el: '#vue',
    }).$mount('#vue')
})

require("@rails/actiontext")
require("trix")
window.Vue = Vue;